export const patientSTGT: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  banner: "Banner - HTML - STGT - DTC - Brazil (PT)",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  stgtTabTop: "DTC - Steps to genetic testing - Top - Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  footerRef: "DTC - Steps to genetic testing - Reference - Brazil (PT)",
  tabTitle: "Steps to genetic testing - Tabbed content - Brazil (PT)",
  geneticTestReveal: "Genetic Test Can Reveal - Steps To Genetic Testing",
  testConfirms: "If a Test Confirms - Steps To Genetic Testing ",
  accordion: "Accordion Section - Steps To Genetic Testing",
  slider: "Slider Section - Step to Genetic Testing",
  backToTopGTM: "back to top - steps to genetic testing",
  tabAttrs: {
    prevStepText: "Anterior",
    nextStepText:"Próximo",
    tabGTMLabels : [{
      tabButtonGTM: {
        'data-gtm-event-label': 'steps to genetic testing tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [
        {
          iconGTMs: [
            {
              'data-gtm-event-label': 'step 1 - schedule an exam',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 2 - meet with an genetic counselor',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 3 - genetic testing will be ordered',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 4 - discuss the results',
              'className': 'gtm-accordion'
            }
          ],
          prevBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 1'
            },
            {
              'data-gtm-event-label': 'step 2 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 2'
            },
            {
              'data-gtm-event-label': 'step 3 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 3'
            },
            {
              'data-gtm-event-label': 'step 4 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 4'
            }
          ],
          nextBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 1'
            },
            {
              'data-gtm-event-label': 'step 2 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 2'
            },
            {
              'data-gtm-event-label': 'step 3 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 3'
            },
            {
              'data-gtm-event-label': 'step 4 - button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 4'
            }
          ]
        }
      ]
    },
    {
      tabButtonGTM: {
        'data-gtm-event-label': 'understanding results tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [{}]
    }
  ]},
  footerClassName: "steps-to-genetic-testing-footer"
}
