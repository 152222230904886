import React, { useEffect, useState } from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper,StepsToGeneticFooterContainer } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import {
  GeneticTesting,
  StepsToGenetic,
  FooterCard
} from './styles'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap'
import TabbedContent from '@retina-packages/retina-theme-bootstrap/packages/components/TabbedComponents'
import retinaConfig from '../../../utils/retinaConfigs'
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder } from '../../../utils';
import { patientSTGT } from './constants'
import { EsHeaderContainer, HomePageFooterContainer } from '../../../css/common/style'
import { tabDataBuilders } from '../../../helpers/Resolvers/ParagraphItemsResolvers/TabbedPage'

/**
 * Renders steps to genetic testing page
 *
 * @param props react property passed dynamically
 * @returns
 */
const InheritedRetinalDiseasesTemplate = (props: Props) => {
  const [renderToggle, setRenderToggle] = useState(false)

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsSTGT = props.pageContext
  const htmlStaticFilesSTGT = {
    nonSVGImages: pgContextsSTGT.nonSVGImages,
    svgMediaImages: pgContextsSTGT.svgMediaImages,
    allMediaDocument: pgContextsSTGT.documents
  }

  // Menu builder
  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: patientSTGT.mainMenu, siteFooterMenu: patientSTGT.footerMenu });

  // Reusable properties building
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = patientSTGT.footer
  const exitPopupTitle = patientSTGT.exitPopup
  const hcpSwitcherTitle = patientSTGT.hcpSwitcher
  const topNavTitle = patientSTGT.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  // TAB data building
  const tabTitle = patientSTGT.tabTitle
  const {
    tabArr,
    tabHead
  }:
  {
    tabHead: never[]
    tabArr: any[]
  } = tabDataBuilders({blocks, tabTitle})

  const tabData = {
    tabData: tabHead,
    tabContent: tabArr
  }

  // STGT Top Paragraph over tabbed content
  const stgtTopParaHTML = blocks.filter((inf: any) => inf.relationships.field_reusable_paragraph.label === patientSTGT.stgtTabTop).map(
    (info: any) => info.relationships.field_reusable_paragraph.relationships.paragraphs.field_para_text_gcso.value
  ).at(0);

  const siteLogos: any = deriveMultiLogo({ blocks, title: patientSTGT.siteLogo});
  const bannerHTML = fullHTMLDataBuilder({ blocks, title: patientSTGT.banner})
  const footerRefHTML = footRefHTMLBuilder({ blocks, title: patientSTGT.footerRef });
  const socialBlock = footRefHTMLBuilder({ blocks, title: patientSTGT.socialBlock });

  useEffect(()=>{
    const pageClass = 'steps-to-genetic-testing-page'
    document.body.className = pageClass

    window.addEventListener('hashchange', () => {
      // Rerender the components for exit popup in second tab.
      if (window.location.hash === '#tab1') {
        setRenderToggle(!renderToggle)
      }
    })
    if (window.location.hash === '#tab1') {
      // Rerender the components for exit popup in second tab.
      setRenderToggle(!renderToggle)
    }
  }, [])

  return (
    <EsHeaderContainer>
      <HomePageFooterContainer>
        <StepsToGeneticFooterContainer>
          <GeneticTesting className="genetic-testing">
            {props.pageContext !== null && props.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props.pageContext.metaInfo} />
            )
            }
            <Layout
              title={"siteTitle"}
              location={props.location}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              data={mainMenu}
              mdata={footerMenu}
              socialBlock={socialBlock}
              footerData={footerText}
              audience={audience}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              siteLogos={siteLogos}
              gtmBackToTopLabel={patientSTGT.backToTopGTM}
              staticMediaFiles={htmlStaticFilesSTGT}
              backToTopContent={retinaConfig.backToTopContent}
              toggleRender={renderToggle}
              hcpValidate={retinaConfig.hcpValidate}
              preIndexUrl = {retinaConfig.preIndexUrl}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              footerClassName={patientSTGT.footerClassName}
              footref={footerRefHTML}
              showRefContent={retinaConfig.showRefContent}
              hideRefContent={retinaConfig.hideRefContent}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
              >
              <PageWrapper className={`pagewrapper genetic-testing`}>
                <div id='tab1'></div>
                {/* Retina Canada Patients - "Steps to Genetic Testing" page - "What are the steps" Banner component */}
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesSTGT} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true}/>
                </MobileBanner>
                <HTMLRenderer html={stgtTopParaHTML} data={htmlStaticFilesSTGT} tagName='div' />
                <StepsToGenetic className="steps-to-genetic-wrap">
                  <div>
                    <FooterCard className="footercardwrapper">
                      <TabbedContent
                        data={tabData}
                        location={props.location}
                        mediaStaticFiles={htmlStaticFilesSTGT}
                        onTabChange={setRenderToggle}
                        toggleRender={renderToggle}
                        showStepStaticText={true}
                        {... patientSTGT.tabAttrs }
                      />
                    </FooterCard>
                  </div>
                </StepsToGenetic>
              </PageWrapper>
            </Layout>
          </GeneticTesting>
        </StepsToGeneticFooterContainer>
      </HomePageFooterContainer>
    </EsHeaderContainer>
  );
}

export default InheritedRetinalDiseasesTemplate

export const pageQuery = graphql`
query($id: Int!) {
  nodePage(drupal_internal__nid: {eq: $id}) {
    relationships {
      ...STGTPageQuery
    }
  }
}
`
